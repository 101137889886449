
require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

import './../../vendor/power-components/livewire-powergrid/dist/powergrid';

Alpine.start();

document.addEventListener('DOMContentLoaded',function(){
    $(document).on('keyup','.telefono',function(){
        new Cleave('.telefono', {
            phone: true,
            delimiter: '-',
            phoneRegionCode: 'MX'
        });
    });
});

Livewire.on('siguiente',paso => {
    Swal.fire({
        title: '¿Deseas guardar y continuar?',
        text: "Esto guardará la información y pasarás al siguiente paso, ya no podrás retroceder",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Continuar!'
        }).then((result) => {
            if (result.isConfirmed) {
                Livewire.emit('store', paso);
            }
     })
});

Livewire.on('delFile',(document) => {
    Swal.fire({
        title: '¿Deseas Eliminarlo?',
        text: "Esto eliminará el archivo por completo",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                Livewire.emit('deleteFile',document);
            }
     })
});


Livewire.on('savedAlert',(message, icono = 'success') => {
    Swal.fire({
        position: 'center',
        icon: icono,
        title: message,
        showConfirmButton: false,
        timer: 2000
      })
});

Livewire.on('errorAlert',(message) => {
    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
      })
});

window.addEventListener('cerrarModal',() => {
    $('#detalle').modal('hide');
});





